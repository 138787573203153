import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { EditIcon } from "components/Icons/EditIcon";
import { ItemTag } from "components/ItemTag";
import { OverflowTooltip } from "components/OverflowTooltip";
import {
  StatusOptionNew,
  StatusTagNew,
} from "components/StatusTag/StatusTagNew";
import { ChangeProductItemStatusModal } from "containers/Projects/components/ChangeProductItemStatusModal/ChangeProductItemStatusModal";
import { HeaderBackButton } from "containers/Projects/components/Header/HeaderBackButton";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ItemStatusOption, ProductType } from "generated/graphql";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { PermissionsGate } from "helpers/Permissions/PermissionsGate";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HeaderLinks, HeaderLinksProps } from "../HeaderLinks";

export type RiskEventDetailsHeaderProps = {
  title: string;
  dataLoading?: boolean;
  saveLoading?: boolean;
  statusOptionId: string;
  productType: ProductType;
  statusOptions: ItemStatusOption[];
  isCurrentUserProductItemOwner?: boolean;
  itemNumber?: string;
  onStatusChange?: (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => void;
  onBack: () => void;
  onEdit: () => void;
} & HeaderLinksProps;

export const RiskEventDetailsHeader: React.FC<RiskEventDetailsHeaderProps> = ({
  title,
  itemNumber,
  dataLoading,
  saveLoading,
  statusOptionId,
  productType,
  statusOptions,
  isCurrentUserProductItemOwner,
  attachmentsCount,
  commentsCount,
  hasMoreComments,
  onAttachmentsClick,
  onCommentsClick,
  onStatusChange,
  onEdit,
  onBack,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { productInstanceId } = useParams();

  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<ItemStatusOption>();

  const sortedStatusOptions = useMemo(() => {
    const sortedOptions = statusOptions.slice();
    sortedOptions.sort((op1, op2) => op1.displayOrder - op2.displayOrder);
    return sortedOptions;
  }, [statusOptions]);

  const triggerStatusChangeFlow = (
    newSelectedOption: StatusOptionNew<string>
  ) => {
    setNewStatus(
      sortedStatusOptions.find((option) => option.id === newSelectedOption.id)!
    );

    setChangeStatusModalOpen(true);
  };

  const handleStatusChange = (reasonId: string, remarks?: string) => {
    onStatusChange?.(newStatus!, reasonId, remarks);
    setChangeStatusModalOpen(false);
    setNewStatus(undefined);
  };

  const showHeaderLinks =
    (attachmentsCount && attachmentsCount > 0) ||
    (commentsCount && commentsCount > 0);

  return (
    <>
      <ChangeProductItemStatusModal
        open={changeStatusModalOpen}
        oldStatusName={
          sortedStatusOptions.find((option) => option.id === statusOptionId)
            ?.description || ""
        }
        newStatusName={newStatus?.description || ""}
        reasons={newStatus?.optionReasons.items || []}
        onClose={() => setChangeStatusModalOpen(false)}
        onSecondaryClick={() => setChangeStatusModalOpen(false)}
        onPrimaryClick={handleStatusChange}
      />
      <ProductItemHeaderContainer>
        {(_isHeaderStuck?: boolean) => (
          <>
            <Box mr={2}>
              <HeaderBackButton onBack={onBack} disabled={dataLoading} />
            </Box>
            {dataLoading ? (
              <Box>
                <Skeleton variant="rectangular" animation="pulse" width={500} />
              </Box>
            ) : (
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack direction="column" alignItems="flex-start">
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <ItemTag
                      type={productType}
                      borderRadius="6px"
                      height="20px !important"
                    />
                    <Typography variant="p2" color="grey.600">
                      {itemNumber}
                    </Typography>
                  </Stack>
                  <OverflowTooltip
                    title={title}
                    typographyProps={{
                      variant: "p1",
                      color: "grey.800",
                      fontWeight: 600,
                      maxWidth: "500px",
                    }}
                  />
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    my: "14px !important",
                    pl: theme.spacing(1),
                  }}
                />
                <Box display="flex" alignItems="center" pl={1}>
                  <StatusTagNew
                    size="small"
                    hideArrow
                    placement="bottom-start"
                    selectedOptionId={statusOptionId}
                    options={sortedStatusOptions.map((statusOption) => ({
                      id: statusOption.id,
                      label: statusOption.description,
                      style: statusOption.style,
                    }))}
                    disabled={!onStatusChange}
                    onChange={triggerStatusChangeFlow}
                  />
                </Box>
              </Stack>
            )}
            {showHeaderLinks ? (
              <Box ml="auto">
                <HeaderLinks
                  attachmentsCount={attachmentsCount}
                  commentsCount={commentsCount}
                  onAttachmentsClick={onAttachmentsClick}
                  onCommentsClick={onCommentsClick}
                  hasMoreComments={hasMoreComments}
                />
              </Box>
            ) : null}
            <PermissionsGate
              productInstanceId={productInstanceId}
              requiredPermissions={[PermissionEnum.Edit]}
              fallbackCheck={isCurrentUserProductItemOwner}
            >
              <Box
                ml={showHeaderLinks ? 4 : "auto"}
                mr={0} /* mr={isHeaderStuck ? 3 : 0} */
              >
                <LoadingButton
                  variant="outlined"
                  onClick={onEdit}
                  loading={saveLoading}
                  size="large"
                  data-testid="edit-btn"
                >
                  <EditIcon />
                  <Typography
                    variant="p2"
                    fontWeight={700}
                    color={theme.palette.grey[800]}
                    ml={1}
                  >
                    {t("common.buttons.edit")}
                  </Typography>
                </LoadingButton>
              </Box>
            </PermissionsGate>
          </>
        )}
      </ProductItemHeaderContainer>
    </>
  );
};
